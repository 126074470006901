import React from "react"
import SEO from "../components/seo";
import { Link } from "gatsby"

const IndexPage = () => (
    <>
      <SEO title="Home" />
      <div className="md:max-w-4xl xl:max-w-6xl mx-auto mb-12 mt-3">

        <div className="m-auto inline-block text-lg tracking-wide font-medium font-display text-my_gray-lighter items-center">
            <img className="inline-block h-32 md:h-56" src={require("../images/book.svg")}  />
            <span className="align-middle text-6xl">Tyria</span>
        </div>



        <div className="sm:block md:flex mt-6">
          <div className="flex-auto">
            <Link to="/timers" className="block card md:mr-2 mb-4 md:text-center hover:bg-my_dark-light">
              <img className="inline h-32" src={require("../images/timers.svg")} />
              <p className="inline md:block font-display tracking-wide text-my_red-light text-3xl align-middle">Timers</p>
            </Link>
          </div>

          <div className="flex-auto">
            <Link to="/wvw" className="block card md:mx-2 mb-4 md:text-center hover:bg-my_dark-light">
              <img className="inline h-32" src={require("../images/wvw.svg")} />
              <p className="inline md:block font-display tracking-wide text-my_green-light text-3xl align-middle">WvW</p>
            </Link>
          </div>

          <div className="flex-auto">
            <Link to="/account" className="block card md:ml-2 mb-4 md:text-center hover:bg-my_dark-light">
              <img className="inline h-32" src={require("../images/account.svg")} />
              <p className="inline md:block font-display tracking-wide text-my_blue-light text-3xl align-middle">Account</p>
            </Link>
          </div>
        </div>

      </div>
    </>
)

export default IndexPage
